<template>
    <div class="container" >
      <ogv-page-header page-title="Foto Galerien"></ogv-page-header>

      <div class="row mb-2">
        <ogv-gallery-entry pageTitle="Vorstellung OGV Schießen" idGallery="Verein" image-alt="Verein OGV Schießen"
                           imageUrl="gallery/Verein/VereinGalery.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Neuanpflanzung Kirche" idGallery="2022_RabatteKirche" image-alt="Neuanpflanzung Kirche"
                           imageUrl="gallery/2022_RabatteKirche/Rabatte30.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Abschluss 90 Jahre - 90 Bäume" idGallery="2022_Abschluss90Jahre" image-alt="90 Jahre"
                           imageUrl="gallery/2022_Abschluss90Jahre/Abschluss01.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Vorfrühling" idGallery="2019_02_fruehling" image-alt="Krokus Steine"
                           imageUrl="gallery/2019_02_fruehling/KrokusSteine.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Kiesgärten" idGallery="kiesgarten" image-alt="Kiesgarten Beth Chatto"
                           imageUrl="gallery/kiesgarten/BethChatto1.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Sheridan Park" idGallery="sheridan" image-alt="Sheridan Park Augsburg"
                           imageUrl="gallery/sheridan/Stauden17.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Frühling" idGallery="GemeindeFruehling" image-alt="Kloster Roggenburg"
                           imageUrl="gallery/GemeindeFruehling/0104SchleebuchKreuzBaum2.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Sommer" idGallery="GemeindeSommer" image-alt="Wegkreuz Schleebuch"
                           imageUrl="gallery/GemeindeSommer/0205RoggenburgKlosterBaume.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Herbst" idGallery="GemeindeHerbst" image-alt="Roggenburger Weiher"
                           imageUrl="gallery/GemeindeHerbst/0303RoggenburgerWeiher.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Winter" idGallery="GemeindeWinter" image-alt="Winter bei Schleebuch"
                           imageUrl="gallery/GemeindeWinter/WinterSchleebuchBaume.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Roggenburger Horizonte" idGallery="RoggenburgerHorizonte" image-alt="Roggenburger Horizonte"
                           imageUrl="gallery/RoggenburgerHorizonte/Gemeinde.JPG"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Roggenburger Sonnenaufgänge" idGallery="RoggenburgSonne" image-alt="Roggenburger Sonnenuntergänge"
                           imageUrl="gallery/RoggenburgSonne/SchiessenSonnenaufgang.JPG"></ogv-gallery-entry>

      </div>
    </div>

</template>

<script>
import GalerieEntry from "@/components/common/GalerieEntry";

 export default {
   name: 'foto-galleries',
   components: {
     'ogv-gallery-entry': GalerieEntry
   },
  data () {
    return {
    }
  }
}
</script>



