<template>
    <div><ogv-event v-bind:event-object='ogvEventForId'></ogv-event></div>
</template>

<script>
    import {eventsRequest, findEventForId} from "../../js/events";

 export default {
    name: 'ogv-event-router',
    props: ['eventId'],
    data: function () {
       return {
            ogvEventForId: { "id": "Invalid",
                        "title": "Event not found"
                       }
         }
     },
     mounted: function() {

         eventsRequest()
            .then(json => {this.ogvEventForId = findEventForId(json, this.eventId)});
    }
}
</script>
