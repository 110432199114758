<template>
    <div class="container">
        <ogv-page-header page-title="Streuobst"></ogv-page-header>
        <div class="row mt-4">
            <div class="col-sm-4 blog-main">
                <div style="height: 300px">
                    <l-map style="height: 100%; width: 100%" :zoom="zoom" :center="center">
                        <l-tile-layer :url="url" :attribution="attribution"/>
                        <l-polygon
                                :lat-lngs="polygon.latlngs"
                                :color="polygon.color">
                        </l-polygon>
                        <l-marker :lat-lng="marker">
                            <l-tooltip>Streuobstwiese Ingstetten</l-tooltip>
                        </l-marker>
                        <l-polygon
                                :lat-lngs="polygon2.latlngs"
                                :color="polygon2.color">
                        </l-polygon>
                        <l-marker :lat-lng="marker2">
                            <l-tooltip>Streuobstwiese Schleebuch</l-tooltip>
                        </l-marker>
                    </l-map>
                </div>
            </div>
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/streuobst/streuobst.md'></ogv-markdown>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/streuobst/baumpaten.md'></ogv-markdown>
            </div>
            <div class="col-sm-4 blog-main">
                <img src='themen/streuobst/StreuobstIngstetten1.JPG' class="img-thumbnail" alt="Streuobstwiese Ingstetten"/>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-4 blog-main">
                <img src='themen/streuobst/StreuobstSchleebuchWinter.JPG' class="img-thumbnail" alt="Streuobstwiese Schleebuch im Winter"/>
            </div>
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/streuobst/streuobstLinks.md'></ogv-markdown>
            </div>
        </div>
    </div>
</template>

<script>

    import { latLng } from 'leaflet';
    import {LMap, LTileLayer, LPolygon, LMarker, LTooltip} from 'vue2-leaflet';

    export default {
        name: 'ogv-streuobst',
        components: {
            LMap,
            LTileLayer,
            LPolygon,
            LMarker,
            LTooltip
        },
        data() {
            return {
                slide: 0,
                zoom: 13,
                url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                place: {
                    city: "Ingstetten",
                    lat: 48.283159,
                    long: 10.252893
                },
                polygon: {
                    latlngs: [[48.282907, 10.251675], [48.283168, 10.251596], [48.283485, 10.254187], [48.283244, 10.254156]],
                    color: 'blue'
                },
                place2: {
                    city: "Schleebuch",
                    lat: 48.289417,
                    long: 10.240496
                },
                polygon2: {
                    latlngs: [[48.289831, 10.240896], [48.288875, 10.240437], [48.289607, 10.240237]],
                    color: 'blue'
                },
                mapOptions: {
                    zoomSnap: 0.5
                }
            }
        },
        computed: {
            city: function () {
                return (this.place) ? this.place.city : null;
            },
            center: function () {
                const lat = 48.287090;
                const long = 10.245778;
                return (lat && long) ? [lat, long] : null;
            },
            marker: function () {
                const lat = (this.place) ? this.place.lat : null;
                const long = (this.place) ? this.place.long : null;
                return (lat && long) ? latLng(lat, long) : null;
            },
            marker2: function () {
                const lat = (this.place2) ? this.place2.lat : null;
                const long = (this.place2) ? this.place2.long : null;
                return (lat && long) ? latLng(lat, long) : null;
            }

        }
    }
</script>

<style>
    @import '../../../node_modules/leaflet/dist/leaflet.css';
</style>

