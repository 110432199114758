<template>
    <div class="row no-gutters mt-4 justify-content-center border-bottom">
        <div class="col-3 col-sm-2">
            <b-img class="text-left img-fluid" src="/logo_ogv_text2_2019_01.png" alt="Logo Ogv Schiessen" />
        </div>
        <div class="col-8 col-sm-8">
          <h1 class="text-center">{{pageTitle}}</h1>
      </div>
      <div class="col-3 col-sm-2">
        <b-img class="text-left img-fluid" src="verein/90Jahre/GemeindeLogo_600px.jpg" alt="Logo Gemeinde Roggenburg" />
      </div>
    </div>

</template>

<script>
 export default {
    name: 'ogv-header-90jahre',
    props: ['pageTitle'],
    data: function () {
       return {
        }
     }
}
</script>
