<template>
  <div class="container">
    <ogv-page-header page-title='Gartenpfleger und Kreisfachberater'></ogv-page-header>

      <div class="row mt-4">
          <div class="col-sm-4 blog-main">
              <img src='verein/gartenpfleger.JPG' class="img-thumbnail" alt="Gartenpfleger Rainer"/>
          </div>
          <div class="col-sm-8 blog-main">
              <h2>Gartenpfleger im Verein</h2>
              <div>
                  In fast jedem Gartenbauverein in Bayern gibt es Gartenpfleger, die die Schnittstelle zwischen Kreisfachberater, Landesverband und den Mitgliedern in den
                  Ortsverbänden bilden.
                  Die Gartenpfleger werden sowohl auf Kreisebene als auch auf Bezirks- und Landesebene ständig fortgebildet.

                  Wer Interesse an der Ausbildung zum Gartenpfleger hat, kann sich jederzeit an die Vorstandschaft des OGV Schießen wenden.
              </div>
              <h3 class="mt-4">Weitere Informationen zu Gartenpflegern und Kreisfachberatern</h3>

              <ul>
                  <li><b-link target="_blank" href="https://www.gartenbauvereine.org/wp-content/uploads/2018/09/Flyer-Gartenpfleger-im-Verein.pdf">Flyer Gartenpfleger</b-link></li>
                  <li><b-link target="_blank" href=" http://www.landkreis.neu-ulm.de/de/kreisfachberatung.html">Kreisfachberater</b-link></li>
              </ul>

              <h3>Unsere Gartenpfleger</h3>
              <p>Bei Fragen zum Thema Garten können Sie sich gerne an uns wenden:</p>
              <div class="row mt-4">
                  <div class="col-lg-4" v-for="vorstand in gartenpfleger" :key="vorstand.id">
                      <b-card
                              :title="vorstand.name"
                              :sub-title="vorstand.eMail"
                              header="Gartenpfleger"
                              header-bg-variant="secondary"
                              img-bottom
                              tag="article"
                              class="mb-2">
                      </b-card>
                  </div>
              </div>
          </div>
      </div>
  </div>

</template>

<script>
 export default {
  name: 'ogv-cards',
  data:  function () {
        return {gartenpfleger: [
            { id: 2,
              name:  'Armin Kast',
              eMail: ''
            },
            { id: 1,
                name:  'Rainer Schneider',
                eMail: 'erster.vorsitzender@ogv-schiessen.de'
            }
        ]
        }
    }
  }
</script>
