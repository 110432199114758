<!--https://github.com/mbj36/vue-burger-menu/blob/master/src/components/Menu.vue-->
<template>
    <div>
        <div id="sideNav" class="bm-menu">
            <nav class="bm-item-list">
                <slot></slot>
            </nav>
        </div>

    </div>
</template>

<script>
    export default {
        name: 'menubar',
        data() {
            return {
                isSideBarOpen: false
            };
        },
        props: {
            isOpen: {
                type: Boolean,
                required: false
            },
            right: {
                type: Boolean,
                required: false
            },
            width: {
                type: [String],
                required: false,
                default: '300'
            },
            disableEsc: {
                type: Boolean,
                required: false
            },
            noOverlay: {
                type: Boolean,
                required: false
            },
            onStateChange: {
                type: Function,
                required: false
            }
        },
        methods: {
            openMenu() {
                this.$emit('openMenu');
                this.isSideBarOpen = true;
                if (!this.noOverlay) {
                    document.body.className += 'bm-overlay';
                }
                if (this.right) {
                    document.querySelector('.bm-menu').style.left = 'auto';
                    document.querySelector('.bm-menu').style.right = '0px';
                }
                this.$nextTick(function() {
                    document.getElementById('sideNav').style.width = this.width
                        ? this.width + 'px'
                        : '300px';
                });
            },
            closeMenu() {
                this.$emit('closeMenu');
                this.isSideBarOpen = false;
                document.body.className = document.body.className.replace(
                    'bm-overlay',
                    ''
                );
                document.getElementById('sideNav').style.width = '0px';
            },
            closeMenuOnEsc(e) {
                e = e || window.event;
                if (e.key === 'Escape' || e.keyCode === 27) {
                    document.getElementById('sideNav').style.width = '0px';
                    document.body.style.backgroundColor = 'inherit';
                    this.isSideBarOpen = false;
                }
            },
            documentClick(e) {
                let element = document.querySelector('.bm-burger-button');
                let target = null;
                if (e && e.target) {
                    target = e.target;
                }
                if (
                    element &&
                    element !== target &&
                    !element.contains(target) &&
                    e.target.className !== 'bm-menu' &&
                    this.isSideBarOpen
                ) {
                    this.closeMenu();
                }
            }
        },
        mounted() {
            if (!this.disableEsc) {
                document.addEventListener('keyup', this.closeMenuOnEsc);
            }
        },
        created: function() {
            //document.addEventListener('click', this.documentClick);
        },
        destroyed: function() {
            document.removeEventListener('keyup', this.closeMenuOnEsc);
            document.removeEventListener('click', this.documentClick);
        },
        watch: {
            isOpen: {
                deep: true,
                immediate: true,
                handler(newValue, oldValue) {
                    if (!oldValue && newValue) {
                        this.openMenu();
                    }
                    if (oldValue && !newValue) {
                        this.closeMenu();
                    }
                }
            },
            right: {
                deep: true,
                immediate: true,
                handler(oldValue, newValue) {
                    if (oldValue) {
                        this.$nextTick(() => {
                            document.querySelector('.bm-menu').style.left = 'auto';
                            document.querySelector('.bm-menu').style.right = '0px';
                        });
                    }
                    if (newValue) {
                        if (
                            document.querySelector('.bm-burger-button').hasAttribute('style')
                        ) {
                            document
                                .querySelector('.bm-burger-button')
                                .removeAttribute('style');
                            document.getElementById('sideNav').style.right = 'auto';
                        }
                    }
                }
            }
        }
    };
</script>

<style>

    .bm-menu {
        height: 100%; /* 100% Full-height */
        width: 0; /* 0 width - change this with JavaScript */
        position: fixed; /* Stay in place */
        z-index: 1000; /* Stay on top */
        top: 0;
        left: 0;
        background-color: rgb(63, 63, 65); /* Black*/
        overflow-x: hidden; /* Disable horizontal scroll */
        padding-top: 60px; /* Place content 60px from the top */
        transition: 0.5s; /*0.5 second transition effect to slide in the sidenav*/
    }
    .bm-overlay {
        /*background: rgba(0, 0, 0, 0.3);*/
    }

</style>
