<template>
    <div class="container" @click="containerClicked">
        <div id="app">
            <main id="page-wrap">
                <ogv-page-header :page-title="pageTitle"></ogv-page-header>
                <div class="row mt-4">
                    <div class="col-sm-10">
                        <div style="height: 100px; min-height: 500px">
                            <l-map style="height: 100%; width: 100%" :zoom="zoom" :center.sync ="center" :maxZoom="iMaxZoom"
                                   @update:zoom="zoomUpdated">
                                <l-tile-layer :url="url" maxNativeZoom=18 :maxZoom="iMaxZoom" :attribution="attribution"
                                    :options="{maxNativeZoom:18, maxZoom:20}"/>
                                <v-marker-cluster :options="{spiderfyOnMaxZoom: false, disableClusteringAtZoom: 17}">
                                    <l-marker v-for="garden in allGardens" :key="garden.n" :lat-lng="markerGarden(garden)">
                                        <l-icon
                                                :icon-size="dynamicSize"
                                                :icon-anchor="dynamicAnchor"
                                                icon-url="themen/leaf-solid.png" >
                                        </l-icon>
                                        <l-popup>
                                            <address>
                                                <strong class="text-info">{{garden.n}}</strong><br>
                                                {{garden.s}}<br>
                                                {{garden.o}} {{garden.ot}}<br>
                                                <b-link v-if="garden.url" target="_blank" :href="garden.url">Homepage</b-link>
                                            </address>
                                            <p>{{garden.t}}</p>
                                        </l-popup>
                                    </l-marker>
                                 </v-marker-cluster>
                            </l-map>
                        </div>
                    </div>

                </div>
            </main>
            <Slide right width="250" :isOpen="menuOpen">
                     <b-button @click.stop="closeMenu" ><font-awesome-icon icon="times" variant="primary"/></b-button>
                    <div class="mb-3 ml-3" @click.stop="">
                    <b-form-radio-group class="mt-4" id="btnradios1"
                                        v-model="selectedRange"
                                        :options="ranges"
                                        name="radiosBtnDefault" />
                    </div>
                    <div>
                    <b-list-group class="mt-1">
                            <b-list-group-item v-for="garden in gardensAroundFilterd" :key="garden.n" button v-on:click.stop="showPanel(garden)">{{garden.n}}</b-list-group-item>
                    </b-list-group>
                    </div>

            </Slide>
        </div>
        <div class="bm-burger-button" @click.stop="openMenu" >
            <font-awesome-icon icon="angle-double-left"/>
        </div>
    </div>
</template>

<script>

    import { latLng } from "leaflet";
    import {LMap, LTileLayer, LPopup, LMarker, LIcon} from 'vue2-leaflet';
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';
    import Slide from '../vue-burger-menu/slide';

    export default {
        name: 'ogv-streuobst',
        components: {
            Slide,
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LIcon,
            'v-marker-cluster': Vue2LeafletMarkerCluster
        },
        props: ['ranges', 'selectedRange', 'allGardens', 'pageTitle'],
        data() {
            return {
                slide: 0,
                zoom: 9,
                url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                iconSize: 20,
                center:  [48.6384, 9.9619],
                menuOpen: false,
                iMaxZoom: 20
            }
        },
        computed: {

            dynamicSize () {
                return [this.iconSize, this.iconSize ];
            },
            dynamicAnchor () {
                return [0, this.iconSize];
            },
            gardensAroundFilterd () {
                return this.allGardens.filter(garden=>garden.k === this.selectedRange);
            }
        },
        methods: {
            markerGarden(garden) {
                const lat = (garden.gps) ? garden.gps.lt : null;
                const long = (garden.gps) ? garden.gps.long : null;
                return (lat && long) ? latLng(lat, long) : null;
            },
            showPanel(garden) {
                const lat = (garden.gps) ? garden.gps.lt : null;
                const long = (garden.gps) ? garden.gps.long : null;
                this.center = latLng(lat, long);
                this.zoom = 14;
                // eslint-disable-next-line
                console.log("Close menu ");

            },
            changedRange() {
                // eslint-disable-next-line
                console.log(this.selectedRange);
            },
            openMenu() {
                // eslint-disable-next-line
                console.log("openMenu");
                this.menuOpen = true;
            },
            closeMenu() {
                // eslint-disable-next-line
                console.log("openMenu");
                this.menuOpen = false;
            },
            containerClicked() {

                // eslint-disable-next-line
                console.log("containerClicked");
                if (this.menuOpen) {
                    this.menuOpen = false;
                }
            },
            zoomUpdated (zoom) {
              this.zoom = zoom;
            }
        }
    }
</script>

<style lang="scss">

    @import '../../../node_modules/leaflet/dist/leaflet.css';
    @import "~leaflet.markercluster/dist/MarkerCluster.css";
    @import "~leaflet.markercluster/dist/MarkerCluster.Default.css";
    @import '../../../node_modules/bootstrap/scss/_functions.scss';
    @import '../../../node_modules/bootstrap/scss/_variables.scss';

    .bm-burger-button {
        position: absolute;
        width: 48px;
        height: 48px;
        left: auto;
        right: 3px;
        top: 50%;
        cursor: pointer;
        z-index: 500;
    }

    .bm-burger-button.hidden {
        display: none;
    }

    .bm-cross {
        background-color: theme-color("primary");
    }
    .cross-style {
        position: absolute;
        top: 72px;
        right: 2px;
        cursor: pointer;
        color: red;
    }

    .bm-menu {
        background-color: theme-color("secondary"); /* Black*/
    }

    .bm-item-list {
        margin-left: 0;
    }

</style>

