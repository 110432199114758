
export function eventsRequest() {

    let eventsRequest = new Request('events/events.json');
    return fetch(eventsRequest)
        .then(response => response.json());
}

export function filterTopEvents(eventsJson, $moment) {

    let topEvents =  eventsJson
                    .filter(event => (event.organizer === "OGV" && isEventValid(event, $moment)))
                    .sort((a, b)=> $moment(a.dateStart, 'YYYY-MM-DD').diff(b.dateStart, 'YYYY-MM-DD'))
                    .slice(0,3);
    return topEvents;
}

export function filterSideBarEvents(eventsJson, $moment) {

    return eventsJson
        .filter(event => isEventValid(event, $moment))
        .sort((a, b)=> $moment(a.dateStart, 'YYYY-MM-DD').diff(b.dateStart, 'YYYY-MM-DD'))
        .slice(0,5);
}

export function filterAllValidEvents(eventsJson, $moment) {


    return eventsJson
        .filter(event => isEventValid(event, $moment))
        .sort((a, b)=> $moment(a.dateStart, 'YYYY-MM-DD').diff(b.dateStart, 'YYYY-MM-DD'));
}


export function findEventForId(eventsJson, eventId) {

    return eventsJson.filter(event => (event.id === eventId))[0];
}

function isEventValid(event, $moment) {

    return (event.dateEnd) ? $moment(event.dateEnd).isSameOrAfter($moment().startOf('day'))
                            : $moment(event.dateStart).isSameOrAfter($moment().startOf('day'));
}