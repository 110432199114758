<template>
    <div class="container" >
    <ogv-page-header page-title="Einfach mal die Welt retten - Komm in unser Vorstands-Team"></ogv-page-header>

    <div class="row mt-4">
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/sucheVorstand/vorstellungZweck.md'></ogv-markdown>
        </div>
        <div class="col-sm-4 blog-main">
            <img src='verein/90Jahre/BaumPflanzen.JPG' class="img-thumbnail" alt="Sonnenaufgang Schiessen"/>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='verein/Mitgliedschaft.JPG' class="img-thumbnail" alt="Streuobstwiese Schleebuch im Winter"/>
        </div>
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/sucheVorstand/wasWirTun.md'></ogv-markdown>
        </div>
    </div>
        <div class="row mt-4">
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='verein/sucheVorstand/aufruf.md'></ogv-markdown>
            </div>
            <div class="col-sm-4 blog-main">
                <img src='verein/verband.JPG' class="img-thumbnail" alt="Chronic 1930"/>
            </div>
        </div>

 </div>
</template>

<script>
 export default {
  name: 'ogv-vorstellung',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



