<template>
    <div class="container" >
        <ogv-page-header :page-title='newsTopic.title'></ogv-page-header>
        <div class="row mt-4">
            <div class="col-sm-7">
                <dl class="row">
                    <dt class="col-3">Datum</dt>
                    <dd class="col-9">{{dateStart}}</dd>

                    <dt class="col-3 border-top">Bereich</dt>
                    <dd class="col-9">{{subject}}</dd>

                    <dt v-if="newsTopic.link" class="col-2 border-top">Link</dt>
                    <dd v-if="newsTopic.link" class="col-9"><b-link  target="_blank" :href="newsTopic.link">Weitere Informationen</b-link></dd>

                    <dt class="col-12 mt-4 mb-2">{{newsTopic.abstract}}</dt>

                </dl>
                <ogv-markdown :markdownurl='markdownUrl'></ogv-markdown>
            </div>
            <div class="col-sm-5">
                <img :src='newsTopic.imageSrc' class="img-thumbnail" alt="Responsive image"/>
            </div>
        </div>
       
    </div>
</template>

<script>

 export default {
    props: ['newsTopic'],
    name: 'ogv-topic',
     components: {

     },
    data: function () {
    
       return {
        }
     },
     computed: {
       dateStart: function () {
         return this.$moment(this.newsTopic.newsDate).format('L');
       },
       subject: function () {
         return (this.newsTopic) ?  this.newsTopic.subject : null;
       },
       center: function () {
            const lat = (this.newsTopic && this.newsTopic.place) ? this.newsTopic.place.lat : null;
            const long = (this.newsTopic && this.newsTopic.place) ? this.newsTopic.place.long : null;
         return (lat&&long) ? [lat, long] : null;
       },
       markdownUrl: function() {
           return (this.newsTopic) ? this.newsTopic.markdownUrl : null;
       }

     }
}
</script>