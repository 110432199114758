<template>
    <div class="row no-gutters mt-4 justify-content-center border-bottom">
        <div class="col-3 col-sm-2">
            <b-img class="text-left img-fluid" src="/logo_ogv_text2_2019_01.png" alt="Logo Ogv Schiessen" />
        </div>
        <div class="col-8 col-sm-10">
          <h3 class="text-right">{{pageTitle}}</h3>
      </div>
    </div>

</template>

<script>
 export default {
    name: 'ogv-page-header',
    props: ['pageTitle'],
    data: function () {
       return {
        }
     }
}
</script>
