<template>
    <div class="mb-4">
        <b-media tag="li">
            <img slot="aside" width="120px" height="80px" :src='newsTopic.imageSrc' :alt="newsTopic.imageSrc"/>
            <small class="text-muted">{{topicDayStart}}</small>
            <small class="ml-4 mb-1">{{newsTopic.subject}}</small>
            <router-link :to="{ name: 'ogvTopic', params: { topicId: newsTopic.id }}">
                <h5 class="mt-0 mb-1">{{newsTopic.title}}</h5>
            </router-link>
            <b-btn v-b-toggle='newsTopic.id' variant="link" size="sm" ><font-awesome-icon icon="plus-square" /></b-btn>
        </b-media>
        <b-collapse :id="newsTopic.id" class="mt-2">
            {{newsTopic.abstract}}
        </b-collapse>
    </div>


</template>

<script>
    export default {
        props: ['newsTopic'],
        name: 'ogv-news-topic',
        data:  function () {
            return {ogvEvents: []
            }
        },
        computed: {
            topicDayStart: function () {
                return this.$moment(this.newsTopic.newsDate).format('DD MMM');
            },
            markdownUrl: function () {
                return (this.newsTopic) ? this.newsTopic.markdownUrl : null;
            }
        }
    }
</script>
<style lang="scss" >



</style>

