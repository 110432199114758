<template>
    <div class="container" >
    <ogv-page-header page-title="Obst- und Gartenbauverein Schießen"></ogv-page-header>

    <div class="row mt-4">
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/vorstellungWillkommen.md'></ogv-markdown>
        </div>
        <div class="col-sm-4 blog-main">
            <img src='verein/Schiessen2.JPG' class="img-thumbnail" alt="Sonnenaufgang Schiessen"/>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='verein/chronic1930.jpg' class="img-thumbnail" alt="Streuobstwiese Schleebuch im Winter"/>
        </div>
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/vorstellungZweck.md'></ogv-markdown>
        </div>
    </div>
        <div class="row mt-4">
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='verein/vorstellungVorteile.md'></ogv-markdown>
            </div>
            <div class="col-sm-4 blog-main">
                <img src='verein/baumschnitt.JPG' class="img-thumbnail" alt="Chronic 1930"/>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-4 blog-main">
                <img src='verein/verband.JPG' class="img-thumbnail" alt="Streuobstwiese Schleebuch im Winter"/>
            </div>
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='verein/vorstellungVerbaende.md'></ogv-markdown>
            </div>
        </div>

 </div>
</template>

<script>
 export default {
  name: 'ogv-vorstellung',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



