<template>
    <ogv-tree-map :allTrees="treesAround"  pageTitle="Streuobst Neu-Ulm"></ogv-tree-map>

</template>

<script>

import {streuobstMapRequest} from "../../js/maps-data";

    export default {
        name: 'ogv-gartentuer-2019',
        data() {
            return {

                treesAround: []

            }
        },
        created: function() {

          streuobstMapRequest()
                .then(json => this.treesAround = json);
        }
    }
</script>

