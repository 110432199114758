<template>
    <ogv-garden-map :ranges="allRanges" :selectedRange="selectedRange" :allGardens="gardensAround" pageTitle="Gärten in der Nähe"></ogv-garden-map>

</template>

<script>

    import {gardensAroundRequest} from "../../js/maps-data";

    export default {
        name: 'ogv-ardens-around',
        data() {
            return {
                allRanges: [
                    { text: 'Ulm', value: 'UL' },
                    { text: 'Stuttgart', value: 'S' },
                    { text: 'Allgäu', value: 'OA'},
                    { text: 'Bayern', value: 'BY' }
                ],
                selectedRange: 'UL',
                gardensAround: []

            }
        },
        created: function() {

            gardensAroundRequest()
                .then(json => this.gardensAround = json);
        }
    }
</script>
