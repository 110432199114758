<template>
    <div class="container" >
    <ogv-header-90jahre page-title="90 Jahre - Gehölzliste"></ogv-header-90jahre>

      <div class="row mt-4">
        <div>
          <b-link target="_blank" href="/verein/90Jahre/90Jahre90BaeumeGehoelzListe.pdf">Gehölzliste herunter laden</b-link>
        </div>
      </div>
      <div class="row mt-4">
          <div class="col-sm-8 blog-main">
              <ogv-markdown markdownurl='verein/90Jahre/BaumListe.md'></ogv-markdown>
          </div>
      </div>

 </div>
</template>

<script>
 export default {
  name: 'ogv-90jahre-90baeume',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



