
export function newsRequest() {

    let newsRequest = new Request('news/news.json');
    return fetch(newsRequest)
        .then(response => response.json());
}

export function filterTopNews(newsJson, $moment) {

    let topNews =  newsJson
                    .sort((a, b)=> $moment(b.newsDate, 'YYYY-MM-DD').diff(a.newsDate, 'YYYY-MM-DD'))
                    .slice(0,2);
    return topNews;
}

export function filterSideBarNews(newsJson, $moment) {

    return newsJson
        .sort((a, b)=> $moment(b.newsDate, 'YYYY-MM-DD').diff(a.newsDate, 'YYYY-MM-DD'))
        .slice(0,3);
}

