<template>
    <div class="container">
        <ogv-page-header page-title="OGV Kids"></ogv-page-header>
        <div class="row mt-4">
        <div class="col-sm-4 blog-main">

            <b-carousel id="carousel1"
                      style="text-shadow: 1px 1px 2px #333;"
                      controls
                      indicators
                      background="#ababab"
                      :interval="4000"
                      v-model="slide">


            <b-carousel-slide caption="Bienenhotel" text="selber bauen" img-src="img/OgvKids2.jpg">
            </b-carousel-slide>

            <b-carousel-slide caption="Basteln" text="mit Naturmaterialien" img-src="img/OgvKids3.jpg">
            </b-carousel-slide>

            <b-carousel-slide caption="Apfelsaft" text="selber pressen" img-src="img/OgvKids1.jpg">
            </b-carousel-slide>

            </b-carousel>
        </div>
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/ogvKids.md'></ogv-markdown>
          <h2>Aktionen</h2>
          <b-card-group columns>
            <router-link :to="{ name: 'ogvKidsSaatgut2021'}">
              <b-card class="info"
                      header-bg-variant="secondary"
                      title="Gärtnern in der 2. Ferienwoche"
                      img-src="verein/kids/samentueten.JPG" img-top>
                <b-card-text>
                  kostenloses Saatgut für Kinder
                </b-card-text>
                <template v-slot:footer>
                  <router-link :to="{ name: 'ogvKidsSaatgut2021'}"><font-awesome-icon icon="link" /> zum Projekt</router-link>
                </template>
              </b-card>
            </router-link>
            </b-card-group>
        </div>

    </div>
 </div>
</template>

<script>
 export default {
  name: 'ogv-carousel',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



