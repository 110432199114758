<template>
    <div>
        <b-button variant="link" to="/impressum">Impressum</b-button>
        <b-button variant="link" to="/datenschutz">Datenschutzerklärung</b-button>
    </div>
</template>

<script>
 export default {
  name: 'ogv-footer',
}
</script>

<style>
    .footer {
        position: absolute;
        bottom: 0;
        width: 100%;
        /* Set the fixed height of the footer here */
        height: 60px;
        line-height: 60px; /* Vertically center the text there */
        background-color: #f5f5f5;
    }
</style>

