<template>
    <fruit-tree-map :allTrees="treesAround"  pageTitle="Streuobst Schwaben"></fruit-tree-map>

</template>

<script>

import {fruitTreeMapRequest} from "../../js/maps-data";

    export default {
        name: 'fruit-tree',
        data() {
            return {

                treesAround: []

            }
        },
        created: function() {

          fruitTreeMapRequest()
                .then(json => this.treesAround = json);
        }
    }
</script>
