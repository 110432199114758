import 'promise-polyfill/src/polyfill';
import 'whatwg-fetch'
import 'abortcontroller-polyfill';
import Vue from 'vue'
import './plugins/bootstrap-vue'
import BootstrapVue from 'bootstrap-vue'
import VueRouter from 'vue-router';
import 'bootstrap/scss/bootstrap.scss'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import './main.scss';
import { library } from '@fortawesome/fontawesome-svg-core'
import {
    faNewspaper,
    faPlusSquare,
    faLeaf,
    faAngleDoubleLeft,
    faTimes,
    faLink,
    faTimesCircle,
    faCalendar
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import OgvMarkdown from './components/common/OgvMarkdown.vue';
import OgPageHeader from './components/common/OgPageHeader.vue';
import Header90Jahre from './components/verein/90jahre/Header90Jahre.vue';
import OgvFooter from './components/OgvFooter.vue';
import OgvHeader from './components/OgvHeader.vue';
import OgvEventsSidebar from './components/OgvEventsSidebar.vue';
import OgvEvent from './components/events/OgvEvent.vue';
import OgvCalendarEvent from './components/calendar/OgvCalendarEvent.vue';

import moment from 'moment';
import {marked} from 'marked';

import router from './router';
import OgvNewsTopic from "./components/news/OgvNewsTopic";
import OgvTopicPage from "./components/news/OgvTopicPage";
import GardensMapPage from "./components/common/GardensMapPage";
import TreeMapPage from "./components/common/TreeMapPage";
import OgvGallery from "./components/gallery/OgvGallery";
import { Icon } from "leaflet";
import FruitTreeMapPage from "@/components/common/FruitTreeMapPage";

delete Icon.Default.prototype._getIconUrl;

Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

Vue.config.productionTip = true;
library.add(faCalendar);
library.add(faNewspaper);
library.add(faPlusSquare);
library.add(faLeaf);
library.add(faAngleDoubleLeft);
library.add(faTimes);
library.add(faLink);
library.add(faTimesCircle);

Vue.component('font-awesome-icon', FontAwesomeIcon);


const myRenderer = new marked.Renderer();
myRenderer.link = function(href, title, text) {
    var external, newWindow, out;
    external = /^https?:\/\/.+$/.test(href);
    newWindow = external || title === 'newWindow';
    out = "<a href=\"" + href + "\"";
    if (newWindow) {
        out += ' target="_blank"';
    }
    if (title && title !== 'newWindow') {
        out += " title=\"" + title + "\"";
    }
    return out += ">" + text + "</a>";
};

marked.setOptions({
    renderer: myRenderer,
    gfm: true,
    tables: true,
    breaks: false,
    pedantic: false,
    sanitize: false,
    smartLists: true,
    smartypants: false,
    xhtml: false
});

//L.Icon.Default.imagePath = "./assets/leaflet/";

const locale = window.navigator.userLanguage || window.navigator.language;
if (locale !== 'en') {
    moment.locale('de');
}

Object.defineProperty(Vue.prototype, '$moment', { value: moment });
Object.defineProperty(Vue.prototype, '$marked', { value: marked });

Vue.use(VueRouter);
Vue.use(BootstrapVue);

Vue.component('ogv-garden-map', GardensMapPage);
Vue.component('ogv-tree-map', TreeMapPage);
Vue.component('fruit-tree-map', FruitTreeMapPage);
Vue.component('ogv-footer', OgvFooter);
Vue.component('ogv-header', OgvHeader);
Vue.component('ogv-events-sidebar', OgvEventsSidebar);
Vue.component('ogv-markdown', OgvMarkdown);
Vue.component('ogv-page-header', OgPageHeader);
Vue.component('ogv-header-90jahre', Header90Jahre)
Vue.component('ogv-event', OgvEvent);
Vue.component('ogv-calendar-event', OgvCalendarEvent);
Vue.component('ogv-news-topic', OgvNewsTopic);
Vue.component('ogv-topic', OgvTopicPage);
Vue.component('ogv-gallery', OgvGallery);


Vue.component('anchored-heading', {
    functional: true,
    render(createElement, context) {
        const first = createElement('h1', { props: context.props });
        const second = createElement('h2', { props: context.props });

        return [first, second];
    }
});

new Vue({
    el: "#appId",
    router
});
