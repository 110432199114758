<template>


    <div class="container">
        <ogv-page-header :page-title='ogvGalleryInfo.name'></ogv-page-header>
        <div id="gallery">
            <vue-easy-lightbox :visible="visible" :imgs="allImages" :index="index" @hide="handleHide"></vue-easy-lightbox>
            <div
                    class="image"
                    v-for="(imageInfo, imageIndex) in allImages"
                    :key="imageIndex"
                    @click="showImg(imageIndex)"
                    :style="{ backgroundImage: 'url(' + imageInfo.src + ')', width: '300px', height: '200px' }"
            ></div>
        </div>
      <router-link :to="{ name: 'FotoGalerien'}">
        <div class="image" :style="{ backgroundImage: 'url(verein/90Jahre/4Jahreszeiten.JPG)', width: '300px', height: '200px' }">
          <div class="display-3 text-white text-center galery-text-shadow" >Weitere Galerien</div>
        </div>
      </router-link>
    </div>

</template>
<script>
    import VueEasyLightbox from 'vue-easy-lightbox';
    export default {
        props: ['ogvGalleryInfo', 'galleryDirectory'],
        name: 'ogv-gallery',
        data: function () {
            return {
              images: ['gallery/2019_02_fruehling/Hamamelis.JPG', 'gallery/2019_02_fruehling/Krokus.JPG'],
              index: 0,
              visible: false
            }
        },
        components: {
          'vue-easy-lightbox': VueEasyLightbox
        },
        computed: {
            allImages: function () {

                return this.calculateImagePaths(this.ogvGalleryInfo.images);
            },
            infoMarkdownFile: function() {
                return (this.ogvGalleryInfo && this.ogvGalleryInfo.markdownUrl)
                    ? 'gallery/' + this.galleryDirectory + '/' +  this.ogvGalleryInfo.markdownUrl
                    : null;
            }
        },
        methods: {
            showImg(index) {
              this.index = index
              this.visible = true
            },
            handleHide() {
              this.visible = false
            },
            calculateImagePaths(infos) {
                return (infos) ? infos.map(imageInfo => {
                          const info  = { 'src': 'gallery/' + this.galleryDirectory + '/' + imageInfo.image,
                                          title: imageInfo.title
                                        }
                          return info;
                        })
                    : [];
            }
        }
    }
</script>
<style>
    .image {
        float: left;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center center;
        border: 1px solid #ebebeb;
        margin: 5px;
    }
    #gallery > div > a.close, #gallery > div > a.next, #gallery > div > a.prev {
        color: #fff;
    }

    .galery-text-shadow {
      text-shadow: 1px 1px 2px black;
    }

</style>
