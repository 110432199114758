<template>
  <div class="container">

    <b-card-group columns>
      <b-card class="info"  v-for="ogvEvent in ogvEvents"
              v-bind:key="ogvEvent.id"
              header-bg-variant="secondary"
              :title="ogvEvent.title"
              :img-src="ogvEvent.imageSrc" img-top
              tag="article">
        <b-card-text>
          <font-awesome-icon icon="calendar" />  {{formattedDate(ogvEvent)}}
          <p v-if="showDaysUntil(ogvEvent)"  class="card-text">in <span class="badge badge-pill  badge-success align-text-bottom mt-2">{{daysUntilStart(ogvEvent)}}</span> Tagen</p>
          <p v-if="isToday(ogvEvent)"  class="card-text"><span class="badge badge-pill  badge-success align-text-bottom mt-2">Heute</span></p>
        </b-card-text>
        <template v-slot:footer>
          <router-link :to="{ name: 'ogvEvent', params: { eventId: ogvEvent.id }}"><font-awesome-icon icon="link" /> zur Veranstaltung</router-link>
        </template>
      </b-card>
        <b-card class="info"
                header-bg-variant="secondary"
                title="Naturgarten  'Bayern blüht'"
                img-src="themen/gartenzertifizierung/naturgartenLogoKlein.jpg" img-top>
            <b-card-text>
                lassen Sie Ihren Garten zertifizieren
            </b-card-text>
            <template v-slot:footer>
                <router-link :to="{ name: 'gartenzertifizierung'}"><font-awesome-icon icon="link" />  zum Projekt</router-link>
            </template>
        </b-card>
        <b-card class="info"  v-for="ogvNewsTopic in ogvTopNews"
                v-bind:key="ogvNewsTopic.id"
                header-bg-variant="secondary"
                :title="ogvNewsTopic.title"
                :img-src="ogvNewsTopic.imageSrc" img-top
                tag="article">
            <b-card-text>
                {{ogvNewsTopic.abstract}}
            </b-card-text>
            <template v-slot:footer>
                <router-link :to="{ name: 'ogvTopic', params: { topicId: ogvNewsTopic.id }}"><font-awesome-icon icon="link" /> zur Neuigkeit</router-link>
            </template>
        </b-card>
      <b-card class="info"
              header-bg-variant="secondary"
              title="Foto Galerie"
              img-src="gallery/RoggenburgSonne/SchiessenSonnenaufgang.JPG" img-top>
        <b-card-text>
          Roggenburger Sonnenaufgänge
        </b-card-text>
        <template v-slot:footer>
          <router-link :to="{ name: 'ogvGallery', params: { galleryId: 'RoggenburgSonne' }}"><font-awesome-icon icon="link" />  zur Galerie</router-link>
        </template>
      </b-card>
      <b-card class="info"
                header-bg-variant="secondary"
                title="Foto Galerie"
                img-src="gallery/GemeindeFruehling/0104SchleebuchKreuzBaum2.jpg" img-top>
          <b-card-text>
            Frühling in unserer Gemeinde
          </b-card-text>
          <template v-slot:footer>
            <router-link :to="{ name: 'ogvGallery', params: { galleryId: 'GemeindeFruehling' }}"><font-awesome-icon icon="link" />  zur Galerie</router-link>
          </template>
        </b-card>

    </b-card-group>
   </div>

</template>

<script>
  import {eventsRequest, filterTopEvents} from "../js/events";
  import {filterTopNews, newsRequest} from "../js/news";

 export default {
  name: 'ogv-cards',
  data:  function () {
        return {ogvEvents: [],
            ogvTopNews: []
        }
    },
     created: function() {
         eventsRequest().then(json => this.ogvEvents = filterTopEvents(json, this.$moment));
         newsRequest().then(json => this.ogvTopNews = filterTopNews(json, this.$moment));
    },
   methods: {
       isToday: function(ogvEvent) {
           const daysUntil = this.daysUntilStart(ogvEvent);
           return daysUntil === 0;
       },
       showDaysUntil: function(ogvEvent) {
           const daysUntil = this.daysUntilStart(ogvEvent);
           return daysUntil > 0 && daysUntil < 14;
       },
       daysUntilStart: function(ogvEvent) {

           const durationUntil = this.$moment.duration(this.$moment(ogvEvent.dateStart).diff(this.$moment()));
           return Math.ceil(durationUntil.asDays());
       },
       formattedDate: function (ogvEvent) {

           const formatedStartDate = this.$moment(ogvEvent.dateStart).format('L');
           return (ogvEvent.dateEnd) ? 'ab ' +  formatedStartDate : formatedStartDate;
       }
   }
  }
</script>



