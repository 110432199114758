export function gardensAroundRequest() {

    let eventsRequest = new Request('themen/gardensAround.json');
    return fetch(eventsRequest)
        .then(response => response.json());
}

export function tagDerOffenenGartentuer(year) {

    let eventsRequest = new Request('themen/tagDerOffenenGartentuer' + year + '.json');
    return fetch(eventsRequest)
        .then(response => response.json());
}

export function streuobstMapRequest() {

    let eventsRequest = new Request('themen/StreuObst.json');
    return fetch(eventsRequest)
        .then(response => response.json());
}

export function fruitTreeMapRequest() {

    let eventsRequest = new Request('themen/streuobst/StreuObst2.json');
    return fetch(eventsRequest)
        .then(response => response.json());
}