<template>
  <div class="container">
    <ogv-page-header page-title='Vorstandschaft'></ogv-page-header>
    <div class="row mt-4">
      <div class="col-lg-4" v-for="vorstand in vorstaende" :key="vorstand.id">
          <b-card
              :title="vorstand.name"
              :sub-title="vorstand.eMail"
              :header= "vorstand.title"
              header-bg-variant="secondary"
              img-bottom
              tag="article"
              class="mb-2">
          </b-card>
      </div>
    </div>
  </div>

</template>

<script>
 export default {
  name: 'ogv-cards',
  data:  function () {
        return {vorstaende: [
            { id: 1,
              name:  'Rainer Schneider',
              title: '1. Vorsitzender',
              eMail: 'erster.vorsitzender@ogv-schiessen.de'
            },
            { id: 2,
              name:  'Gisela Hille-Reh',
              title: '2. Vorsitzende',
              eMail: 'zweite.vorsitzende@ogv-schiessen.de'
            },
            { id: 3,
              name:  'Georg Engelmayer',
              title: 'Kassierer',
              eMail: 'kasse@ogv-schiessen.de'
            },
            { id: 4,
              name:  'Helga Gass',
              title: 'Schriftführer',
              eMail: 'schriftfuehrerin@ogv-schiessen.de'
            },
            { id: 5,
              name:  'Sylvia Lecheler',
              title: 'Beisitzerin',
              eMail: ''
            },
            { id: 6,
              name:  'Fred Scholz',
              title: 'Beisitzer',
              eMail: ''
            },
            { id: 6,
              name:  'Jessica Breuel',
              title: 'Beisitzerin',
              eMail: ''
            }
        ]
        }
    }
  }
</script>
