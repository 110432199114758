<template>
    <div class="container" >
    <ogv-page-header page-title="Mitmachen"></ogv-page-header>
    <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='verein/Mitgliedschaft.JPG' class="img-thumbnail" alt="Mitmachen"/>
        </div>
        <div class="col-sm-8 blog-main">
            <div>
                <h2>Wollen Sie bei uns Mitmachen?</h2>
                <p>Hier finden Sie die Beitrittserklärung für Ihre Mitgliedschaft bei Obst- und Gartenbauverein Schießen e.V. zum herunterladen</p>
            </div>
            <div>
                <b-link target="_blank" href="/verein/MitgliedsantragOGVSchiessen.pdf">Mitgliedsantrag herunter laden</b-link>
            </div>

        </div>

    </div>
 </div>
</template>

<script>
 export default {
  name: 'ogv-mitgliedschaft',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



