<template>
    <div class="container" >
        <ogv-page-header page-title="Kalender"></ogv-page-header>
        <div class="mb-4"></div>
        <ogv-calendar-event  v-for="(ogvEvent, index) in ogvEvents" :key="ogvEvent.id" v-bind:event-object='ogvEvent' v-bind:event-index='index' >
        </ogv-calendar-event>

    </div>

</template>

<script>
    import {eventsRequest, filterAllValidEvents} from "../../js/events";

    export default {
        name: 'ogv-calendar',
        data:  function () {
            return {ogvEvents: []
            }
        },
        computed: {

        },
        created: function() {

            eventsRequest()
                .then(json => {
                    // eslint-disable-next-line
                    console.log( filterAllValidEvents(json, this.$moment));
                    this.ogvEvents = filterAllValidEvents(json, this.$moment)
                });
        },
        methods: {
            isEventValid(event) {
                return this.$moment(event.dateStart).isAfter(this.$moment());
            }
        }
    }
</script>


