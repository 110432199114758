<template>
    <div class="container">
        <ogv-page-header page-title="Gartenzertifizierung"></ogv-page-header>
        <div class="row mt-4">
            <div class="col-sm-4 blog-main">
                <img src='themen/gartenzertifizierung/naturgartenLogoKlein.jpg' class="img-thumbnail" alt="Naturgarten Bayern blüht"/>
            </div>
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/gartenzertifizierung/gartenzertifizierung.md'></ogv-markdown>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/gartenzertifizierung/kernkriterien.md'></ogv-markdown>
            </div>
            <div class="col-sm-4 blog-main">
                <img src='themen/gartenzertifizierung/diversitaet.JPG' class="img-thumbnail" alt="ökologische Vielfalt"/>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-4 blog-main">
                <b-embed type="video" controls autoplay loop muted="1" poster="poster.png">
                    <source src="themen/gartenzertifizierung/biene.mp4" type="video/mp4">
                </b-embed>
            </div>
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/gartenzertifizierung/kannkriterienNaturgarten.md'></ogv-markdown>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-sm-8 blog-main">
                <ogv-markdown markdownurl='themen/gartenzertifizierung/kannkriterienBewirtschaftung.md'></ogv-markdown>
            </div>
            <div class="col-sm-4 blog-main">
                <img src='themen/gartenzertifizierung/nutzgarten.JPG' class="img-thumbnail" alt="Nutzgarten"/>
            </div>
        </div>
      <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='themen/gartenzertifizierung/schmetterling.JPG' class="img-thumbnail" alt="Naturgarten Bayern blüht"/>
        </div>
        <div class="col-sm-8 blog-main">
          <ogv-markdown markdownurl='themen/gartenzertifizierung/linksNaturgarten.md'></ogv-markdown>
        </div>
      </div>

    </div>
</template>

<script>

    export default {
        name: 'ogv-gartenzertifizierung',
        components: {
        },
        data() {
            return {
            }
        },
        computed: {
        }
    }
</script>



