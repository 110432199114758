<template>
    <div class="container" >
        <ogv-page-header page-title="Neuigkeiten"></ogv-page-header>
        <div class="mb-4"></div>
        <ul class="list-unstyled">
            <ogv-news-topic v-for="ogvNewsTopic in ogvNewsSorted" :key="ogvNewsTopic.id" v-bind:news-topic='ogvNewsTopic'>
            </ogv-news-topic>
        </ul>

    </div>

</template>

<script>
    import {newsRequest} from "../../js/news";

    export default {
        name: 'ogv-news',
        data:  function () {
            return {ogvNews: []
            }
        },
        computed: {
            ogvNewsSorted: function () {
                return this.ogvNews
                    .slice()
                    .sort((topicA, topicB)=>this.$moment(topicB.newsDate, 'YYYY-MM-DD').diff(topicA.newsDate, 'YYYY-MM-DD'));
            }
        },
        created: function() {
            newsRequest()
                .then(json => this.ogvNews = json);
        },
        methods: {

        }
    }
</script>


