<template>
<div>
    <b-list-group flush class="mt-4">
        <b-list-group-item variant="primary"><font-awesome-icon icon="newspaper" />  Aktuelles</b-list-group-item>
        <b-list-group-item  v-for="ogvTopic in ogvNewsSorted" :key="ogvTopic.id"  class="flex-column align-items-start">
            <router-link class="text-primary" :to="{ name: 'ogvTopic', params: { topicId: ogvTopic.id }}">
                <small>{{formattedDate(ogvTopic.newsDate)}} </small>
                <h5 class="mb-1">{{ogvTopic.title}}</h5>
            </router-link>
        </b-list-group-item>
        <b-list-group-item><b-link to="/news"><small>Weitere Neuigkeiten ...</small></b-link></b-list-group-item>



      <b-list-group-item variant="primary"><font-awesome-icon icon="calendar" />  Termine</b-list-group-item>
      <b-list-group-item  v-for="ogvEvent in ogvEvents" :key="ogvEvent.id"  class="flex-column align-items-start">
        <router-link class="text-primary" :to="{ name: 'ogvEvent', params: { eventId: ogvEvent.id }}">
            <h5 class="mb-1 text-primary">{{ogvEvent.title}}</h5>
             <small>{{formattedDate(ogvEvent.dateStart)}} </small>
             <small>{{ogvEvent.place.city}}</small>
         </router-link>
      </b-list-group-item>
        <b-list-group-item><b-link to="/calendar"><small>Weitere Termine ...</small></b-link></b-list-group-item>
    </b-list-group>
</div>

</template>

<script>

 import {eventsRequest, filterSideBarEvents} from "../js/events";
 import {filterSideBarNews, newsRequest} from "../js/news";

 export default {
  name: 'ogv-events-sidebar',
  data:  function () {
        return {
            ogvEvents: [],
            ogvNewsSorted: []
        }
    },
    created: function() {

        eventsRequest()
            .then(json => this.ogvEvents = filterSideBarEvents(json, this.$moment));

         newsRequest()
             .then(json => this.ogvNewsSorted = filterSideBarNews(json, this.$moment));
     },
    methods: {
     formattedDate: function (dateToFormat) {
        return this.$moment(dateToFormat).format('L');
     }
    }
  }
</script>


