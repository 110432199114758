<template>
    <div class="container" >
    <ogv-header-90jahre page-title="90 Jahre - 90 Bäume"></ogv-header-90jahre>

    <div class="row mt-4">
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/90Jahre/90JahreOgv.md'></ogv-markdown>
        </div>
        <div class="col-sm-4 blog-main">
            <img src='verein/chronic1930.jpg' class="img-thumbnail" alt="Chronic 1930"/>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='verein/90Jahre/BaumBirneSchleebuch.JPG' class="img-thumbnail" alt="Birnbaum Schleebuch"/>
        </div>
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/90Jahre/90Baeume.md'></ogv-markdown>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/90Jahre/Mitmachen.md'></ogv-markdown>
        </div>
        <div class="col-sm-4 blog-main">
            <img src='verein/90Jahre/BaumPflanzen.JPG' class="img-thumbnail" alt="Chronic 1930"/>
        </div>
    </div>
    <div class="row mt-4">
        <div class="col-sm-4 blog-main">
            <img src='verein/90Jahre/WilleBaum01.JPG' class="img-thumbnail" alt="Wo ein Wille ist, ist auch ein Baum"/>
        </div>
        <div class="col-sm-8 blog-main">
            <ogv-markdown markdownurl='verein/90Jahre/90JahreAusblick.md'></ogv-markdown>
        </div>
    </div>

 </div>
</template>

<script>
 export default {
  name: 'ogv-90jahre-90baeume',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



