<template>
  <div class="row mt-4">
      <b-carousel id="carousel1"
                style="text-shadow: 1px 1px 2px #333;"
                controls
                indicators
                background="#ababab"
                :interval="4000"
                v-model="slide">

      <!-- Text slides with image -->
      <b-carousel-slide>
          <router-link :to="{ name: 'ogvVorstellung'}">
            <h4 class="text-decoration-none">Obst- und Gartenbauverein Schießen</h4>
          </router-link>
          <img slot="img" class="d-block ogv-img-carousel w-100"
             src="img/schiessen.jpg" alt="image slot">
      </b-carousel-slide>

      <b-carousel-slide text="">
          <router-link :to="{ name: 'ogvKids'}">
              <h3>OGV Kids</h3>
          </router-link>
          <img slot="img" class="d-block ogv-img-carousel w-100"
                 src="img/ogvkids.jpg" alt="image slot">
          </b-carousel-slide>

      <b-carousel-slide text="Baumpate werden">
          <router-link :to="{ name: 'streuobst'}">
              <h3>Streuobst</h3>
          </router-link>
          <img slot="img" class="d-block ogv-img-carousel w-100"
                 src="img/streuobst.jpg" alt="image slot">
      </b-carousel-slide>


      </b-carousel>
 
 </div>
</template>

<script>
 export default {
  name: 'ogv-carousel',
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>
<style>
    .carousel {
        margin-bottom: 4rem;
    }

    .carousel-caption a {
        color:white;
    }
</style>
