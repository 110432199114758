<template>
    <div class="container" >

      <div class="p-4 p-md-5 mb-4 mt-4 text-white bg-primary">
        <h1 class="">Gärtnern in der Osterzeit</h1>
        <div class="row mb-2">
          <div class="col-md-6 px-0">
            <p class="lead my-3">Der OGV Schießen schenkt Kindern Samen zum Aussäen von Kresse, Radieschen und Kapuzinerkresse.</p>
            <p class="lead my-3">Das Saatgut wird in der 2. Osterwoche an alle verteilt.</p>
            <p class="lead my-3">Besonders mutige Gärtner, die sich trauen, bekommen auch noch einen PopMais mit.</p>
            <p class="lead my-3">Alle, die uns dann noch Fotos senden von ihren Pflanzen, erhalten noch einen kleinen Gärtnerpreis.</p>
          </div>
          <div class="col-md-5 mt-4 ml-4 mr-4 px-0">
            <b-img thumbnail src='verein/kids/samentueten.JPG' alt="Kresse Samen"/>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row g-0 mr-2 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <strong class="d-inline-block mb-2 text-primary">Die Schnellen</strong>
              <h3 class="mb-0">Kresse</h3>
              <div class="mb-1 text-muted">04.04.</div>
              <p class="card-text mb-auto">
                Kresse wächst sehr schnell und man braucht nicht einmal Erde um sie auszusäen.
                Am besten weicht Ihr das Saatgut einen Tag in Wasser ein und verteilt es dann auf Küchenkrepp.
                Von der Kresse könnt Ihr schon nach wenigen Tagen die Keimlinge verwenden. Einfach mit der Schere abscheiden.
                Am besten schmecken sie auf einem Butterbrot mit Salz.
              </p>
              <b-link class="text-info" target="_blank" href="https://www.vielfaltsmacher.de/osterhasen-aussaeen/">
                Hier erfahrt Ihr, wie Ihr einen Osterhasen aussät
              </b-link>
            </div>
            <div class="col-auto d-none d-lg-block">
              <b-img thumbnail src='verein/kids/SamenKresse.JPG' alt="Kresse Samen"/>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <strong class="d-inline-block mb-2 text-success">Die Leckeren</strong>
              <h3 class="mb-0">Radieschen</h3>
              <div class="mb-1 text-muted">04.04.</div>
              <p class="mb-auto">Radieschen sind sehr vielfältig.
                Radieschen kann man fast überall anbauen, sowohl im Gartenbeet als auch im Balkonkasten.</p>
              Und sie können meist schon nach 30 Tagen geerntet werden.
                Wir haben für Euch zwei verschiedene Sorten, die runde 'Cherry Belle' und die längliche 'French Breakfast 3'
                mit roter Spitze. Lasst Euch überraschen, welche Ihr bekommen habt.
              <b-link class="text-info" target="_blank" href="https://www.vielfaltsmacher.de/radieschen-aussaeen/">Radieschen aussäen</b-link>
            </div>
            <div class="col-auto d-none d-lg-block">
              <b-img thumbnail fluid src='verein/kids/SamenRadieschen.JPG' alt="Radieschen Samen"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <div class="col-md-6">
          <div class="row g-0 border mr-2 rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <strong class="d-inline-block mb-2 text-primary">Die Hübschen</strong>
              <h3 class="mb-0">Kapuzinerkresse</h3>
              <div class="mb-1 text-muted">04.04.</div>
              <p class="card-text mb-auto">
                Kapuzinerkresse hört sich zwar wie Kresse an, ist aber was anderes. Sie wird hauptsächlich wegen ihrer
                hübschen Blüten angebaut. Diese kann man aber auch essen. Da die Kapuzinerkresse aus tropischen Gegenden kommt,
                mag sie es warm. Sie darf bis Mitte Mai nicht ins Freie.
              </p>
              <b-link class="text-info" target="_blank" href="https://www.selbstversorger.de/kapuzinerkresse-anbauen/">
                Mehr zum Anbau von Kapuzinerkresse erfahrt Ihr hier
              </b-link>
            </div>
            <div class="col-auto d-none d-lg-block">
              <b-img thumbnail src='verein/kids/SamenKapuzinerkresse.JPG' alt="Kapuzinerkresse Samen"/>
            </div>
          </div>
        </div>
        <div class="col-md-6">
          <div class="row g-0 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
            <div class="col p-4 d-flex flex-column position-static">
              <strong class="d-inline-block mb-2 text-primary">Gemüse des Jahres</strong>
              <h3 class="mb-0">Mais</h3>
              <div class="mb-1 text-muted">04.04.</div>
              <p class="card-text mb-auto">
                Mais ist das Gemüse das Jahres. Ihr kennt es wahrscheinlich nur vom Acker,
                man kann es aber auch im eigenen Garten anbauen. Es gibt Mais in allen möglichen Farben rot, gelb, grün , weiß
                und sogar schwarz. Vom Mais gibt es ganz unterschiedliche Sorten, für alle möglichen Zwecke:
              </p>
              <ul>
                <li>Zucker-Mais, den man als Gemüse verwenden kann</li>
                <li>Pop(Puff)-Mais, aus dem man Popcorn machen kann</li>
                <li>Weich-Mais, den man zur Herstellung von Maismehl verwendet</li>
                <li>Hart-Mais, den man als Tierfutter verwendet</li>
              </ul>
              <b-link class="text-info" target="_blank" href="https://www.nutzpflanzenvielfalt.de/gem%C3%BCse-des-jahres-202122-mais">
                Mehr zum Mais erfahrt ihr hier
              </b-link>
            </div>
            <div class="col-auto d-none d-lg-block">
              <b-img thumbnail src='verein/kids/SamenMais.JPG' alt="Mais Samen"/>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <kids-blog-entry pageTitle1="Kresse in Eierschalen" pageTitle2="Eierköpfe"  datum="05.04"
                         inhalt="Kresse kann man auch einfach in Eierschalen aussäen.
                            Wenn Ihr diese dann noch mit Augen und einem Mund bemalt habt Ihr einen lustigen
                            Eierkopf. Einfach Erde oder Watte in eine leere Eierschale geben und Kresse Samen darauf geben.
                            Dann immer feucht halten."
                         linkText="So sät Ihr Samen in Eierschalen." linkUrl="https://www.mdr.de/mdr-garten/gestalten/ostern-nester-eier-gestalten-mit-kresse-100.html"
                         imageUrl="verein/kids/KresseEierkopf.JPG" imageAlt="Kresse Eierköpfe"></kids-blog-entry>
        <kids-blog-entry pageTitle1="Frostige Nächte" pageTitle2="Auf das Wetter achten"  datum="05.04"
                         inhalt="Achtet beim Aussäen auch auf das Wetter, da viele Aussaaten  frostempfindlich sind.
                         Diese Woche soll es in der Nacht sehr kalt werden, deswegen solltet Ihr eure Pflanzen noch nicht
                         ins Freie säen."
                         linkText="" linkUrl=""
                         imageUrl="verein/kids/Frost1.JPG" imageAlt="Frost Krone"></kids-blog-entry>
      </div>
      <div class="row mb-2">
        <kids-blog-entry pageTitle1="Los geht's" pageTitle2="Samentüten"  datum="06.04"
                         inhalt="Heute sind die Samentüten auf die Reise gegangen. Wir hoffen, Ihr habt viel Spaß damit."
                         imageUrl="verein/kids/Samentuetenbox.JPG" imageAlt="Samentütenbox"></kids-blog-entry>
        <kids-blog-entry pageTitle1="Upcycling" pageTitle2="Aussaat in Klopapierrolle"  datum="07.04"
                         inhalt="Ihr könnt Eure Kapuzinerkresse auch einfach in Klopapierrollen vorziehen.
                          Einfach eine Klopapierrolle unten verschließen, mit Erde füllen, ein bis zwei Samen
                          reingeben und mit ca. 1 cm Erde bedecken."
                         linkText="ausführliche Anleitung" linkUrl="https://www.upcyclers.at/aussaat-in-klopapierrolle/"
                         imageUrl="verein/kids/Klopapierrolle.JPG" imageAlt="Klopapierrolle mit Aussaat"></kids-blog-entry>
      </div>
      <div class="row mb-2">
        <kids-blog-entry pageTitle1="Galerie" pageTitle2="Eure Fotos"  datum="08.04"
                         inhalt="Hier geht es zur Galerie mit Euren Fotos."
                         linkText="zur Galerie" linkUrl="#/gallery/2021_04_kids"
                         imageUrl="gallery/2021_04_kids/Maxi.jpg" imageAlt="Maxis Kresse"></kids-blog-entry>
      </div>

    </div>

</template>

<script>
 import KidsBlogEntry from "@/components/verein/kids/KidsBlogEntry";

 export default {
   name: 'kids-saatgut',
   components: {
     'kids-blog-entry': KidsBlogEntry
   },
  data () {
    return {
      slide: 0,
      sliding: null
    }
  }
}
</script>



