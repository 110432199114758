<template>
    <div class="container" >
        <ogv-page-header :page-title='eventObject.title'></ogv-page-header>
        <div class="row mt-4">
            <div class="col-sm-7">
                <dl class="row">
                    <dt class="col-2 border-top">am</dt>
                    <dd class="col-10">{{dateStart}}</dd>

                    <dt class="col-2 border-top">um</dt>
                    <dd class="col-10">{{eventObject.timeStart}}</dd>

                    <dt class="col-2 border-top">Ort</dt>
                    <dd class="col-10">{{city}}</dd>

                    <dt class="col-2"></dt>
                    <dd v-if="eventObject.link" class="col-9"><b-link  target="_blank" :href="eventObject.link">Weitere Informationen</b-link></dd>

                  </dl>
                   <ogv-markdown :markdownurl='markdownUrl'></ogv-markdown>
            </div>
            <div class="col-sm-5">
                <img :src='eventObject.imageSrc' class="img-thumbnail" alt="Responsive image"/>
                <div v-if="center" style="height: 300px">
                       <l-map :zoom="zoom" :center="center">
                           <l-tile-layer :url="url" :attribution="attribution"/>
                        <l-marker :lat-lng="marker"></l-marker>
                    </l-map>
                </div>
            </div>
        </div>
       
    </div>
</template>

<script>

 import { latLng } from "leaflet";
 import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';

 export default {
    props: ['eventObject'],
    name: 'ogv-event',
     components: {
         LMap,
         LTileLayer,
         LMarker
     },
    data: function () {
    
       return {
            zoom: 15,
            url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
            attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        }
     },
     computed: {
       dateStart: function () {
         return this.$moment(this.eventObject.dateStart).format('L');
       },
       city: function () {
         return (this.eventObject && this.eventObject.place) ? this.eventObject.place.city : null;
       },
       center: function () {
            const lat = (this.eventObject && this.eventObject.place) ? this.eventObject.place.lat : null;
            const long = (this.eventObject && this.eventObject.place) ? this.eventObject.place.long : null;
         return (lat&&long) ? [lat, long] : null;
       },
       marker: function () {
            const lat = (this.eventObject && this.eventObject.place) ? this.eventObject.place.lat : null;
            const long = (this.eventObject && this.eventObject.place) ? this.eventObject.place.long : null;
         return (lat&&long) ?  latLng(lat, long) : null;
       },
       markdownUrl: function() {
           return (this.eventObject) ? this.eventObject.markdownUrl : null;
       }

     }
}
</script>
<style>
    @import '../../../node_modules/leaflet/dist/leaflet.css';
</style>
