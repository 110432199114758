<template>
    <div class="container">
        <div id="app">
            <main id="page-wrap">
                <ogv-page-header :page-title="pageTitle"></ogv-page-header>
                <b-form-row class="mt-4">
                  <b-col cols="2"><label>Filter</label></b-col>
                  <b-col cols="9">
                    <b-form-input list="bezeichnungen-list-id" v-model="filterText" placeholder="Sorten Suche" debounce="500"></b-form-input>

                    <datalist id="bezeichnungen-list-id">
                      <option v-for="name in treesNames" :key="name">{{ name }}</option>
                    </datalist>
                  </b-col>
                  <b-col cols="1">
                      <b-button @click="filterText=''" variant="primary" size="sm" class="mb-2">
                        <font-awesome-icon icon="times-circle" />
                      </b-button>
                  </b-col>
                </b-form-row>
                <b-form-row class="mt-4">
                    <b-col cols="12">
                        <div style="height: 100px; min-height: 500px">
                            <l-map style="height: 100%; width: 100%" :zoom="zoom" :center.sync ="center" :maxZoom="iMaxZoom"
                                   @update:zoom="zoomUpdated">
                                <l-control-layers position="topright"></l-control-layers>
                                <l-tile-layer
                                  v-for="tileProvider in tileProviders"
                                  :key="tileProvider.name"
                                  :name="tileProvider.name"
                                  :visible="tileProvider.visible"
                                  :url="tileProvider.url"
                                  :attribution="tileProvider.attribution"
                                  layer-type="base"
                                  :options="{maxNativeZoom:18, maxZoom:20}"
                                  maxNativeZoom=18 :maxZoom="iMaxZoom"
                                />
                                <v-marker-cluster :options="{spiderfyOnMaxZoom: false, disableClusteringAtZoom: 17}">
                                    <l-marker v-for="garden in treesAroundFilterd" :key="garden.id" :lat-lng="markerGarden(garden)">
                                        <l-icon
                                                :icon-size="dynamicSize"
                                                :icon-anchor="dynamicAnchor"
                                                icon-url="themen/leaf-solid.png" >
                                        </l-icon>
                                        <l-popup>
                                            <address>
                                                <strong class="text-info">{{garden.name}}</strong><br>
                                                {{garden.strasse}}<br>
                                                {{garden.ort}}<br>
                                                <b-link v-if="garden.url" target="_blank" :href="garden.url">Homepage</b-link>
                                            </address>
                                            <p>{{garden.beschreibung}}</p>
                                        </l-popup>
                                    </l-marker>
                                 </v-marker-cluster>
                            </l-map>
                        </div>
                    </b-col>

                </b-form-row>
            </main>
        </div>
    </div>
</template>

<script>

    import { latLng } from "leaflet";
    import {LMap, LTileLayer, LPopup, LMarker, LIcon, LControlLayers} from 'vue2-leaflet';
    import Vue2LeafletMarkerCluster from 'vue2-leaflet-markercluster';

    export default {
        name: 'ogv-tree-map',
        components: {
            LMap,
            LTileLayer,
            LMarker,
            LPopup,
            LIcon,
            LControlLayers,
            'v-marker-cluster': Vue2LeafletMarkerCluster
        },
        props: ['allTrees', 'pageTitle'],
        data() {
            return {
                slide: 0,
                zoom: 9,
                url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                attribution: '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors',
                iconSize: 20,
                center:  [48.6384, 9.9619],
                menuOpen: false,
                iMaxZoom: 20,
                filterText:"",
                tileProviders: [
                  {
                    name: 'OpenStreetMap',
                    visible: true,
                    attribution:'&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
                    url: 'https://{s}.tile.openstreetmap.de/tiles/osmde/{z}/{x}/{y}.png',
                  },
                  {
                    name: 'Esri.WorldImagery',
                    visible: false,
                    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
                    attribution:
                        'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
                  }
                ]
            }
        },
        computed: {

            dynamicSize () {
                return [this.iconSize, this.iconSize ];
            },
            dynamicAnchor () {
                return [0, this.iconSize];
            },
            treesAroundFilterd () {
                return this.allTrees.filter(tree=>this.filterText.length === 0
                    || tree.beschreibung.toLowerCase().includes(this.filterText.toLowerCase()))
            },
            treesNames () {
              const bezeichnungen = this.allTrees.map(tree=>tree.beschreibung);
              return [...new Set(bezeichnungen)].sort(); // unique bezeichnungen
            }
        },
        methods: {
            markerGarden(garden) {
                const lat = (garden.place) ? garden.place.lat : null;
                const long = (garden.place) ? garden.place.long : null;
                return (lat && long) ? latLng(lat, long) : null;
            },
             zoomUpdated (zoom) {
              this.zoom = zoom;
            }
        }
    }
</script>



