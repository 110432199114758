<template>

  <div class="col-md-6">
    <div class="row g-0 mr-2 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <h3 class="mb-0">{{pageTitle}}</h3>
      </div>
      <div class="col-auto d-lg-block">
        <router-link :to="{ name: 'ogvGallery', params: { galleryId: idGallery }}">
          <!--                <font-awesome-icon icon="link" />  zur Galerie-->
          <b-img thumbnail fluid :src=imageUrl :alt="imageAlt"/>
        </router-link>
      </div>
    </div>
  </div>

</template>

<script>
 export default {
    name: 'ogv-gallery-entry',
    props: ['pageTitle', 'idGallery', 'imageUrl', 'imageAlt'],
    data: function () {
       return {
        }
     }
}
</script>
