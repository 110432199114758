<template>
    <div class="container mb-4">
        <div class="row align-items-center no-gutters">
            <div class="col-2 col-sm-1 ogv-termin-datum ">
                <div class="text-center font-weight-bold">{{eventDayStart}}</div>
                <div class="text-center font-weight-bold">{{eventMonthStart}}</div>
            </div>

            <div :class="classTitle">
                <div class="row ogv-termin-title no-gutters border-bottom ogv-termin-border" :class="borderForIndex()">
                    <div class="col-12 col-sm-12 pl-2">
                        <router-link :to="{ name: 'ogvEvent', params: { eventId: eventObject.id }}">
                            {{eventObject.title}}
                        </router-link>
                    </div>
                </div>
                <div class="row no-gutters ogv-termin-uhrzeit">
                    <div class="col-4 col-sm-4 pl-2">
                        {{eventObject.timeStart}}
                    </div>
                    <div class="col-8 col-sm-8 pl-2  ogv-termin-uhrzeit">
                        {{eventCity}}
                    </div>
                </div>
            </div>

            <div v-if="eventObject.dateEnd" class="col-2 col-sm-1 ogv-termin-datum">
                <div class="text-center font-weight-bold">{{eventDayEnd}}</div>
                <div class="text-center font-weight-bold">{{eventMonthEnd}}</div>
            </div>

        </div>
    </div>

</template>

<script>
    export default {
        props: ['eventObject', 'eventIndex'],
        name: 'ogv-calendar-event',
        data:  function () {
            return {ogvEvents: []
            }
        },
        computed: {
            eventDayStart: function () {
                return this.$moment(this.eventObject.dateStart).format('DD');
            },
            eventMonthStart: function () {
                return this.$moment(this.eventObject.dateStart).format('MMM');
            },
            eventDayEnd: function () {
                return this.$moment(this.eventObject.dateEnd).format('DD');
            },
            eventMonthEnd: function () {
                return this.$moment(this.eventObject.dateEnd).format('MMM');
            },
            eventCity: function () {
                return (this.eventObject && this.eventObject.place) ? this.eventObject.place.city : null;
            },
            markdownUrl: function () {
                return (this.eventObject) ? this.eventObject.markdownUrl : null;
            },
            classTitle: function () {

                return (this.eventObject.dateEnd)
                    ? 'col-8 col-sm-10 border-left border-right ogv-termin-border ' + this.borderForIndex()
                    : 'col-9 col-sm-11 border-left ogv-termin-border ' + this.borderForIndex()
            }
        },
        methods: {
            formattedDate: function (dateToFormat) {
                return this.$moment(dateToFormat).format('L');
            },
            borderForIndex: function() {
                return (this.eventIndex % 2 == 0) ? 'border-secondary ' : 'border-primary ';
            }
        }
    }
</script>
<style lang="scss" >

    @import '../../../node_modules/bootstrap/scss/_functions.scss';
    @import '../../../node_modules/bootstrap/scss/_variables.scss';
    @import '../../../node_modules/bootstrap/scss/mixins/_breakpoints.scss';

    .ogv-termin-border {
        border-width:2px !important;
    }

    @include media-breakpoint-up(sm) {
        .ogv-termin-title {
            font-size: 24px;
        }
        .ogv-termin-datum {
            font-size: 24px;
        }
        .ogv-termin-uhrzeit {
            font-size: 0.9rem;
        }
    }

    @include media-breakpoint-between(xs, sm) {
        .ogv-termin-title {
            font-size: 1rem;
            font-stretch: condensed;
        }
        .ogv-termin-datum {
            font-size: 1rem;
            font-stretch: condensed;
        }
        .ogv-termin-uhrzeit {
            font-size: 0.9rem;
        }
    }

</style>

