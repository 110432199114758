<template>
    <ogv-garden-map :ranges="allRanges" :selectedRange="selectedRange" :allGardens="gardensAround"  pageTitle="Tag der offenen Gartentür 2023"></ogv-garden-map>

</template>

<script>

    import {tagDerOffenenGartentuer} from "../../js/maps-data";

    export default {
        name: 'ogv-gartentuer-2023',
        data() {
            return {
                allRanges: [
                    { tooltip: 'Neu-Ulm', text:'NU', value: 'NU' },
                    { tooltip: 'Günzburg', text:'GZ',value: 'GZ' },
                    { tooltip: 'Unterallgäu', text:'MN',value: 'MN'},
                    { tooltip: 'Aichach-Friedberg', text:'AIC', value: 'AIC' },
                    { tooltip: 'Lindau', text:'LI', value: 'LI' },
                    { tooltip: 'Ostallgäu', text:'OAL', value: 'OAL'},
                    { tooltip: 'Oberallgäu und Kempten', text:'OA', value: 'OA'},
                    { tooltip: 'Donau-Ries', text:'DON', value: 'DON'},
                    { tooltip: 'Dillingen', text:'DLG', value: 'DLG'},
                    { tooltip: 'Augsburg', text:'A', value: 'A' },

                ],
                selectedRange: 'NU',
                gardensAround: []

            }
        },
        created: function() {

            tagDerOffenenGartentuer(2023)
                .then(json => this.gardensAround = json);
        }
    }
</script>

