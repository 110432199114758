<template>
    <div class="container">
        <ogv-page-header page-title="Wetter Roggenburg"></ogv-page-header>
        <div class="row mt-4">
          Last upate: {{lastUpdate}}
        </div>
        <div class="row mt-4">
            <div class="col-sm-6 blog-main">Temperatur:</div>
            <div class="col-sm-6 blog-main"> {{ airTemperatur }} C°  </div>
        </div>
      <div class="row mt-4">
        <div class="col-sm-6 blog-main">Luftfeuchte:</div>
        <div class="col-sm-6 blog-main"> {{ humidity }} %  </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6 blog-main">Windgeschwindigkeit:</div>
        <div class="col-sm-6 blog-main"> {{ windSpeed }} m/s  </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6 blog-main">Windrichtung:</div>
        <div class="col-sm-6 blog-main"> {{ windDirection }}  </div>
      </div>
      <div class="row mt-4">
        <div class="col-sm-6 blog-main">Temperatur 2:</div>
        <div class="col-sm-6 blog-main"> {{ temperatur2 }}  </div>
      </div>
    </div>
</template>

<script>
  import mqtt from 'mqtt'


    export default {
        name: 'ogv-gartenzertifizierung',
        components: {
        },
        data() {
            return {
              connectionStatus: false,
              airTemperatur: "-",
              humidity: "-",
              windSpeed: "-",
              windDirection: "-",
              temperatur2: "-",
              lastUpdate: "-"
            }
        },
        computed: {
        },
        mounted: function() {

          console.log('Mounted');
          const windDir = ["N","NNE","NE","ENE","E","ESE","SE","SSE","S","SSW","SW","WSW","W","WNW","NW","NNW"];
          const options = {
            username : "hennastall",
            password: "jalfksdf12.Zh45"
          }

          console.log('Try Connected to websocket');
          var client  = mqtt.connect('wss://zeitwerkwunder.de:9001', options)
          console.log('client created');

          client.on('connect', () => {
            console.log('Connected');
            this.connectionStatus = true;
            client.subscribe('weather/#', function (err) {
              if (!err) {
                console.log('Subscribed');
              }
            })
          });
          client.on('message', (topic, payload) => {
            this.lastUpdate = new Date();
            if('weather/Station1/AirTemperature' === topic) {
              this.airTemperatur = payload;
            }
            if('weather/Station1/Humidity' === topic) {
              this.humidity = payload;
            }
            if('weather/Station1/WindSpeed' === topic) {
              this.windSpeed = payload;
            }
            if('weather/Station1/WindDirection' === topic) {

              this.windDirection = windDir[Number(payload)];
            }
            if('weather/Hennastall1/AirTemperature' === topic) {

              this.temperatur2 = payload;
            }

          });
          client.on('error', error => {
            console.log('Connection failed', error);
          })
        }
    }
</script>



