<template>
  <div class="col-md-6">
    <div class="row g-0 mr-2 border rounded overflow-hidden flex-md-row mb-4 shadow-sm h-md-250 position-relative">
      <div class="col p-4 d-flex flex-column position-static">
        <strong class="d-inline-block mb-2 text-primary">{{pageTitle1}}</strong>
        <h3 class="mb-0">{{pageTitle2}}</h3>
        <div class="mb-1 text-muted">{{datum}}</div>
        <p class="card-text mb-auto">
          {{inhalt}}
        </p>
        <b-link v-if="linkUrl" class="text-info" target="_blank" :href="linkUrl">
          {{linkText}}
        </b-link>
      </div>
      <div class="col-auto d-lg-block">
        <b-img thumbnail fluid :src='imageUrl' :alt="imageAlt"/>
      </div>
    </div>
  </div>

</template>

<script>
 export default {
    name: 'kids-blog-entry',
    props: ['pageTitle1', 'pageTitle2', 'datum', 'inhalt', 'linkText', 'linkUrl', 'imageUrl', 'imageAlt'],
    data: function () {
       return {
        }
     }
}
</script>
