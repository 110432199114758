<template>
    <div><ogv-topic v-bind:news-topic='ogvTopicForId'></ogv-topic></div>
</template>

<script>
 export default {
    name: 'ogv-news-router',
    props: ['topicId'],
    data: function () {
       return {
            ogvTopicForId: { "id": "Invalid",
                        "title": "News not found"
                       }
         }
     },
     mounted: function() {
        var myRequest = new Request('news/news.json');
        fetch(myRequest)
            .then(response => response.json())
            .then(json => {this.ogvTopicForId = json.filter(topic => (topic.id === this.topicId))[0];
                        });

    }
}
</script>
