<template>

    <div class="container">
      <div class="row row-offcanvas row-offcanvas-right">
        <div class="col-md-9">
            <ogv-carousel></ogv-carousel>
            <ogv-cards></ogv-cards>
        </div>
        <div class="col-sm-4 col-md-6 col-lg-3" id="sidebar">
            <ogv-events-sidebar></ogv-events-sidebar>
        </div>
      </div>
    </div>
</template>

<script>
import OgvCards from './OgvCards.vue';
import OgvCarousel from './OgvCarousel.vue';

export default {
  name: 'ogv-home',
  components: {
		OgvCards, OgvCarousel
	},
  }

</script>



