<template>
    <div class="container" >
      <ogv-header-90jahre page-title="90 Jahre - 90 Bäume"></ogv-header-90jahre>

      <div class="p-4 p-md-5 mb-4 mt-4 text-white bg-primary">
        <h1 class="">Foto Galerien</h1>
        <div class="row mb-2">
          <div class="col-md-6 px-0">
            <p class="lead my-3">Was wäre die Gemeinde Roggenburg ohne Bäume?</p>
            <p class="lead my-3">Bäume sind sowohl aus der Landschaft, als auch aus den Dörfern nicht wegzudenken.</p>
            <p class="lead my-3">In den folgenden Galerien finden Sie Beispiele, wie sehr Bäume unsere Gemeinde prägen.</p>
            <p class="lead my-3">Das sollte doch ein Anreiz sein, diese zu erhalten.</p>
          </div>
          <div class="col-md-5 mt-4 ml-4 mr-4 px-0">
            <b-img thumbnail src='gallery/GemeindeSommer/0203Gemeinderoggenburg2.jpg' alt="Blick auf die Gemeinde Roggenburg"/>
          </div>
        </div>
      </div>
      <div class="row mb-2">
        <ogv-gallery-entry pageTitle="Frühling" idGallery="GemeindeFruehling" image-alt="Kloster Roggenburg"
                           imageUrl="gallery/GemeindeFruehling/0104SchleebuchKreuzBaum2.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Sommer" idGallery="GemeindeSommer" image-alt="Wegkreuz Schleebuch"
                           imageUrl="gallery/GemeindeSommer/0205RoggenburgKlosterBaume.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Herbst" idGallery="GemeindeHerbst" image-alt="Roggenburger Weiher"
                           imageUrl="gallery/GemeindeHerbst/0303RoggenburgerWeiher.jpg"></ogv-gallery-entry>
        <ogv-gallery-entry pageTitle="Winter" idGallery="GemeindeWinter" image-alt="Winter bei Schleebuch"
                           imageUrl="gallery/GemeindeWinter/WinterSchleebuchBaume.JPG"></ogv-gallery-entry>
      </div>
    </div>

</template>

<script>
import GalerieEntry from "@/components/common/GalerieEntry";

 export default {
   name: 'ogv-90-galerien',
   components: {
     'ogv-gallery-entry': GalerieEntry
   },
  data () {
    return {
    }
  }
}
</script>



