<template>
    <div><ogv-gallery :ogv-gallery-info='galleryInfoForId' :galleryDirectory='galleryDirectory'></ogv-gallery></div>
</template>

<script>
 export default {
    name: 'ogv-gallery-router',
    props: ['galleryId'],
    data: function () {
       return {
           galleryInfoForId: { "name": "<Invalid>",
                        "images": []
                       }
         }
     },
     computed: {
         galleryDirectory: function () {
             return this.galleryId;
         }
     },
     mounted: function() {
        var myRequest = new Request('gallery/'+this.galleryId+'/info.json');
        fetch(myRequest)
            .then(response => response.json())
            .then(responseJson => this.galleryInfoForId = responseJson);

    }
}
</script>
